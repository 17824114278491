import { z } from 'zod';

export const controlledGlobalData = z.object({
  device: z.object({
    appSessionId: z.string().uuid(),
    callId: z.string().uuid(),
    dayOfWeek: z.union([
      z.literal('sunday'),
      z.literal('monday'),
      z.literal('tuesday'),
      z.literal('wednesday'),
      z.literal('thursday'),
      z.literal('friday'),
      z.literal('saturday'),
    ]),
    gpcEnabled: z.boolean(),
    hourOfDay: z.number().int().gte(0).lte(23),
    language: z.string(),
    screenOrientation: z.union([z.literal('landscape'), z.literal('portrait')]),
    timezone: z.string(),
    userAgent: z.string(),
  }),
  event: z.object({
    capturedTimestamp: z.number().int().nonnegative(),
    loggedTimestamp: z.number().int().nonnegative(),
  }),
  querystring: z.record(z.string()),
  session: z.object({
    sequenceNumber: z.number().int().gte(0),
  }),
  view: z.object({
    pageURL: z.string(),
  }),
});

export const uncontrolledGlobalData = z.object({
  device: z.object({
    appVersion: z.string(),
    host: z.string(),
    id: z.string().uuid(),
    isPlaying: z.boolean(),
    lat: z.number().nullable().optional(),
    lng: z.number().nullable().optional(),
    volume: z.number().int().gte(0).lte(100),
    referer: z.string().optional(),
    env: z.string().optional(),
  }),
  user: z
    .object({
      abTestGroup: z.array(z.string()).optional(),
      genreIsDefault: z.boolean(),
      genreSelected: z.array(z.number().int().nonnegative()),
      isTrialEligible: z.boolean(),
      profileId: z.string(),
      privacyOptOut: z.boolean(),
      registration: z
        .object({
          birthYear: z.number().int().nonnegative().optional(),
          country: z.string(),
          gender: z.string().optional(),
          type: z.string(),
          zip: z.string().optional(),
        })
        .optional(),
      skuPromotionType: z.string().optional(),
      subscriptionTier: z.string(),
    })
    .optional(),
});

export const globalData = z.intersection(
  controlledGlobalData,
  uncontrolledGlobalData,
);

export const artistAsset = z.intersection(
  z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal('artist').optional(),
  }),
  z
    .discriminatedUnion('subtype', [
      z.object({
        subtype: z.literal('album'),
        subid: z.number(),
        subname: z.string(),
      }),
      z.object({
        subtype: z.literal('radio'),
      }),
      z.object({
        subtype: z.literal('top-songs'),
        subid: z.number(),
        subname: z.string(),
      }),
    ])
    .optional(),
);

export const favoritesAsset = z.object({
  id: z.string(),
  name: z.string(),
  type: z.literal('favorites').optional(),
  subtype: z.union([z.literal('my'), z.literal('shared')]).optional(),
});

export const liveAsset = z.object({
  id: z.string(),
  name: z.string(),
  type: z.literal('live').optional(),
});

export const playlistAsset = z.object({
  id: z.string(),
  name: z.string(),
  type: z.literal('playlist').optional(),
  subtype: z.union([
    z.literal('curated'),
    z.literal('my'),
    z.literal('new_for_you'),
    z.literal('radio'),
    z.literal('shared_user'),
    z.literal('user').optional(),
  ]),
});

export const podcastAsset = z.object({
  id: z.string(),
  name: z.string(),
  subid: z.string().optional(),
  subname: z.string().optional(),
  subtype: z.literal('episode').optional(),
  type: z.literal('podcast').optional(),
});

export const asset = z.object({
  asset: z.union([
    artistAsset,
    favoritesAsset,
    liveAsset,
    playlistAsset,
    podcastAsset,
  ]),
});

export const media = z.object({
  hadPreroll: z.boolean(),
  isSaved: z.boolean(),
  playedFrom: z.number(),
  sessionId: z.string(),
  startPosition: z.number(),
  streamInitTime: z.number(),
  playbackStartTime: z.number().optional(),
});

export const itemSelected = z.object({
  event: z.object({ location: z.string(), type: z.string().optional() }),
  station: asset,
  item: z.object({
    asset: z.object({
      id: z.string(),
      name: z.string(),
    }),
  }),
  view: z.object({
    itemPosition: z.number(),
    item: z.object({
      row: z.number(),
      column: z.number(),
    }),
    sectionPosition: z.number(),
    section: z.object({
      name: z.string(),
    }),
  }),
});

export const stationAsset = z.object({
  id: z.string(),
  name: z.string(),
  sub: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional(),
});

export const click = z.object({
  event: z.object({
    location: z.string(),
    filter: z
      .object({
        type: z.string(),
        selection: z.string(),
      })
      .optional(),
  }),
  view: z.object({
    pageName: z.string(),
    station: stationAsset.optional(),
    tab: z.string().nullable().optional(),
    section: z
      .object({
        name: z.string(),
      })
      .optional(),
  }),
  pageName: z.string(),
  window: z.object({
    location: z.object({
      href: z.string(),
    }),
  }),
});

export const pageView = z.object({
  pageName: z.string(),

  view: z
    .object({
      asset: stationAsset,
    })
    .optional(),
  window: z.object({
    location: z.object({
      href: z.string(),
    }),
  }),
});

export const postLogin = z.object({
  profileId: z.string(),
  authtype: z.string().optional(),
});

export const postRegistration = z.object({
  profileId: z.string(),
  authtype: z.string().optional(),
});

export const regGateExit = z.object({
  regGate: z.object({
    exitType: z.string(),
    trigger: z.string(),
    type: z.union([z.literal('hard_gate'), z.literal('soft_gate')]),
  }),
});

export const regGateOpen = z.object({
  regGate: z.object({
    trigger: z.string(),
    type: z.union([z.literal('hard_gate'), z.literal('soft_gate')]),
  }),
});

export const streamEnd = z.object({
  station: z.intersection(
    z.intersection(asset, media),
    z.object({
      listenTime: z.number(),
    }),
  ),
});

export const streamStart = z.object({
  station: z.intersection(asset, media),
});

export const trackEnd = z.object({
  station: z.intersection(
    z.intersection(asset, media),
    z.object({
      endReason: z.string(),
      subSessionId: z.string(),
      listenTime: z.number(),
    }),
  ),
});

export const trackStart = z.object({
  station: z.intersection(
    z.intersection(asset, media),
    z.object({
      subSessionId: z.string(),
    }),
  ),
});

export const search = z.object({
  station: z
    .object({
      asset: stationAsset,
    })
    .optional(),
  search: z.object({
    userSearchTerm: z.string(),
    screen: z.string(),
    selectionCategory: z.string().optional(),
    queryId: z.string(),
    exitType: z.string(),
    selectionCategoryPosition: z.number(),
  }),
});

export const displayAdImpression = z.object({
  ad: z.object({
    advertiserId: z.number().optional(),
    campaignId: z.number().optional(),
    clickthroughUrl: z.string(),
    client: z.string(),
    creativeId: z.number().optional(),
    lineItemId: z.number().optional(),
    position: z.string(),
    serviceName: z.string(),
    size: z.object({
      width: z.number().optional(),
      height: z.number().optional(),
    }),
    tag: z.string().optional(),
    targeting: z.record(z.union([z.string(), z.array(z.string())])),
    viewable: z.number(),
  }),
});

export const displayAdError = z.object({
  ad: z.object({
    error: z.string(),
    position: z.string().optional(),
  }),
});

export const followUnfollow = z.object({
  station: z.intersection(asset, z.object({ savedType: z.string() })),
  event: z.object({ location: z.string() }),
});

export const forward30Back15 = z.object({
  station: z.intersection(
    z.object({ asset: podcastAsset }),
    z.object({ playheadPosition: z.string() }),
  ),
  pageName: z.string(),
  item: z.object({ asset: podcastAsset }),
});

export const appOpen = z.object({
  remote: z.object({ location: z.string() }),
  session: z.object({ initializationTime: z.number() }),
});

export const volumeChange = z.object({
  device: z.object({
    oldVolume: z.number(),
    newVolume: z.number(),
  }),
  station: asset,
});

export const appClose = z.object({
  event: z.object({ location: z.string() }),
});

export const inAppMessageOpen = z.object({
  iam: z.object({
    messageType: z.string(),
    userTriggered: z.boolean().optional(),
  }),
  view: z.object({
    pageName: z.string(),
    tab: z.string().optional(),
    station: stationAsset.optional(),
  }),
});

export const inAppMessageExit = z.object({
  iam: z.object({
    messageType: z.string(),
    userTriggered: z.boolean().optional(),
    exitType: z.string().optional(),
  }),
  view: z.object({
    pageName: z.string(),
    tab: z.string().optional(),
    station: stationAsset.optional(),
  }),
});

export const lyricsOpen = z.object({
  lyrics: z
    .object({
      is_available: z.number(),
    })
    .optional(),
  view: z.object({
    pageName: z.string(),
    tab: z.string().optional(),
    station: stationAsset.optional(),
  }),
  event: z.object({ location: z.string() }),
});

export const lyricsClose = z.object({
  lyrics: z
    .object({
      is_available: z.boolean(),
    })
    .optional(),
  view: z.object({
    pageName: z.string(),
    tab: z.string().optional(),
    station: stationAsset.optional(),
  }),
  event: z.object({ type: z.string() }),
});

export const screenView = z.object({
  view: z.object({
    pageName: z.string(),
    tab: z.string().optional(),
    station: stationAsset.optional(),
  }),
});

export const searchStart = z.object({
  search: z.object({
    sessionId: z.string(),
  }),
  event: z.object({
    action: z.string(),
    type: z.string(),
  }),
  station: z
    .object({
      asset: podcastAsset,
    })
    .optional(),
  view: z.object({
    pageName: z.string(),
  }),
});

export const searchOpen = z.object({
  search: z.object({
    sessionId: z.string(),
  }),
  event: z.object({ location: z.string() }),
  station: z
    .object({
      name: z.string(),
    })
    .optional(),
  view: z.object({
    pageName: z.string(),
  }),
});

export const scanStarted = z.object({
  view: z.object({
    pageName: z.string()
  }),
  filter: z.object({
    location: z.string(),
    genre: z.string(),
  })
});

export const stopTypeSchema = z.union([
  z.literal('background'),
  z.literal('miniplayer'),
  z.literal('stop_scan_button'),
  z.literal('radio_dial_play'),
  z.literal('auto_end'),
  z.literal('nav_away'),
  z.literal('preset_play'),
  z.literal('filter_change'),
]).default('auto_end');

export type ScanStopType = z.infer<typeof stopTypeSchema>;

export const scanStopped = z.object({
  view: z.object({
    pageName: z.string()
  }),
  scan: z.object({
    stopType: stopTypeSchema
  })
})

export const eventType = z.nativeEnum({
  Click: 'click',
  ItemSelected: 'item_selected',
  PageView: 'page_view',
  PostLogin: 'post_login',
  PostRegistration: 'post_registration',
  RegGateExit: 'reg_gate_exit',
  RegGateOpen: 'reg_gate_open',
  StreamEnd: 'stream_end',
  StreamStart: 'stream_start',
  TrackEnd: 'track_end',
  TrackStart: 'track_start',
  Search: 'search',
  DisplayAdImpression: 'display_ad_impression',
  DisplayAdClick: 'display_ad_click',
  DisplayAdError: 'display_ad_error',
  Background: 'background',
  Foreground: 'foreground',
  FollowUnfollow: 'follow_unfollow',
  Forward30: 'forward_30',
  Back15: 'back_15',
  AppOpen: 'app_open',
  AppClose: 'app_close',
  VolumeChange: 'volume_change',
  SearchStart: 'search_start',
  SearchOpen: 'search_open',
  InAppMessageOpen: 'iam_open',
  InAppMessageExit: 'iam_exit',
  LyricsOpen: 'lyrics_open',
  LyricsClose: 'lyrics_close',
  ScreenView: 'screen_view',
  ScanStarted: 'scan_started',
  ScanStopped: 'scan_stopped',
} as const);

type Values<T> = {
  [K in keyof T]: T[K];
}[keyof T];

export type EventType = Values<typeof eventType.enum>;

export const event = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(eventType.enum.ItemSelected),
    data: itemSelected,
  }),
  z.object({ type: z.literal(eventType.enum.Click), data: click }),
  z.object({ type: z.literal(eventType.enum.PageView), data: pageView }),
  z.object({ type: z.literal(eventType.enum.PostLogin), data: postLogin }),
  z.object({
    type: z.literal(eventType.enum.PostRegistration),
    data: postRegistration,
  }),
  z.object({ type: z.literal(eventType.enum.RegGateExit), data: regGateExit }),
  z.object({ type: z.literal(eventType.enum.RegGateOpen), data: regGateOpen }),
  z.object({ type: z.literal(eventType.enum.Search), data: search }),
  z.object({ type: z.literal(eventType.enum.StreamEnd), data: streamEnd }),
  z.object({ type: z.literal(eventType.enum.StreamStart), data: streamStart }),
  z.object({ type: z.literal(eventType.enum.TrackEnd), data: trackEnd }),
  z.object({ type: z.literal(eventType.enum.TrackStart), data: trackStart }),
  z.object({
    type: z.literal(eventType.enum.DisplayAdImpression),
    data: displayAdImpression,
  }),
  z.object({
    type: z.literal(eventType.enum.DisplayAdClick),
    data: displayAdImpression,
  }),
  z.object({
    type: z.literal(eventType.enum.DisplayAdError),
    data: displayAdError,
  }),
  z.object({ type: z.literal(eventType.enum.Background), data: z.undefined() }),
  z.object({ type: z.literal(eventType.enum.Foreground), data: z.undefined() }),
  z.object({
    type: z.literal(eventType.enum.FollowUnfollow),
    data: followUnfollow,
  }),
  z.object({
    type: z.literal(eventType.enum.Forward30),
    data: forward30Back15,
  }),
  z.object({
    type: z.literal(eventType.enum.Back15),
    data: forward30Back15,
  }),
  z.object({
    type: z.literal(eventType.enum.AppOpen),
    data: appOpen,
  }),
  z.object({
    type: z.literal(eventType.enum.VolumeChange),
    data: volumeChange,
  }),
  z.object({
    type: z.literal(eventType.enum.AppClose),
    data: appClose,
  }),
  z.object({
    type: z.literal(eventType.enum.InAppMessageOpen),
    data: inAppMessageOpen,
  }),
  z.object({
    type: z.literal(eventType.enum.InAppMessageExit),
    data: inAppMessageExit,
  }),
  z.object({
    type: z.literal(eventType.enum.LyricsOpen),
    data: lyricsOpen,
  }),
  z.object({
    type: z.literal(eventType.enum.LyricsClose),
    data: lyricsClose,
  }),
  z.object({
    type: z.literal(eventType.enum.ScreenView),
    data: screenView,
  }),
  z.object({
    type: z.literal(eventType.enum.SearchStart),
    data: searchStart,
  }),
  z.object({
    type: z.literal(eventType.enum.SearchOpen),
    data: searchOpen,
  }),
  z.object({
    type: z.literal(eventType.enum.ScanStarted),
    data: scanStarted
  }),
  z.object({
    type: z.literal(eventType.enum.ScanStopped),
    data: scanStopped,
  })
]);

export const analyticsData = z.intersection(globalData, event);
